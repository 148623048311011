/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap'); */


.backgroundImage {
  background: url("../../../../public/img/bg2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  /* border:5px solid red; */
  box-sizing: border-box;
  overflow: hidden;
}

.wrapperLogin {
  max-width: 350px;
  min-height: 500px;
  margin: auto;
  padding: 10px 0px 0px 0px;
  background-color: #ecf0f3;
  border-radius: 15px;
  box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
  margin-top: 6%;
}

.logo {
  width: 200px;
  margin: auto;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 50%; */
  /* border-radius: 10%;
  box-shadow: 0px 0px 3px #5f5f5f, 0px 0px 0px 5px #ecf0f3, 8px 8px 15px #a7aaa7,
    -8px -8px 15px #fff; */
}

.wrapperLogin .name {
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 1px;
  padding-left: 10px;
  color: #555;
}

.wrapperLogin .form-field input {
  width: 100%;
  display: block;
  border: none;
  outline: none;
  background: none;
  font-size: 1rem;
  color: #666;
  padding: 10px 15px 10px 10px;
  border-radius: 20px;
}

.wrapperLogin .form-field {
  padding-left: 10px;
  margin-bottom: 25px;
  border-radius: 20px;
  box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
  /* background: none; */
  color: slategray;
}

.wrapperLogin .form-field .fas {
  color: #555;
}

.wrapperLogin .btn {
  box-shadow: none;
  width: 100%;
  height: 50px;
  background-color: #242d6d;
  color: #fff;
  border-radius: 25px;
  box-shadow: 3px 3px 3px #b1b1b1, -3px -3px 3px #fff;
  letter-spacing: 1px;
  font-size: 24px;
}

.wrapperLogin .btn:hover {
  background-color: #005c9e;
}

/* .wrapperLogin a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03A9F4
}

.wrapperLogin a:hover {
    color: #039BE5
} */

@media (max-width: 380px) {
  .wrapperLogin {
    margin: 30px 20px;
    padding: 40px 15px 15px 15px;
  }
}

.input-user-name {
  color: red;
  margin-top: .25rem;
}

input {
  padding: .5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
  0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

::-webkit-input-placeholder {
  font-size: 20px!important;
}