/*
    DEMO STYLE
*/
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  height: 50px;
  /* padding: 15px 10px; */
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 10px;
  box-shadow: 1px 3px 3px rgb(0 0 0 / 25%);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

i,
span {
  display: inline-block;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  /* background: #7386D5;
    color: #fff; */
  background: #ecf0f3;
  color: #0a2442;
  transition: all 0.3s;
  height: auto;
}

#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}

#sidebar ul li a {
  text-align: left;
}

#sidebar.active ul li a {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
}

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
}

#sidebar.active ul ul a {
  padding: 10px !important;
  font-size: 0.8rem !important;
}

#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#sidebar .sidebar-header {
  padding: 3px;
  padding-top: 5px;
  background: #186CA9;
  height: 50px;
  text-align: center;
}

.sidebar-header h3 {
  font-size: 1.1em;
  margin-bottom: 0px;
  padding-top: 10px;
  color: #fff;
}

/* new css */
.main-sidebar{
  /* height: inherit; */
  /* min-height: 100%; */
  min-height:100%;
  top: 0px;
  transition: all 0.3s ease 0s;
  position: absolute;
  background-color: #F2F3F4;
  left: 0;
  z-index: 810;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

#sidebar ul.components {
  padding: 20px 0;
  /* border-bottom: 1px solid #47748b; */
}

#sidebar ul li a {
  padding: 10px;
  font-size: 18px;
  display: block;
}

#sidebar ul li a:hover {
  color: #0000ff;
  background: #cfcbcb;
}

#sidebar ul li a i {
  margin-right: 10px;
}

/* #sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6584ff;
} */

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #0000ff;
  background: #bbb8b8;
}

#moduleControl ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6584ff;
}

#adminManage[aria-expanded="true"] {
  color: #fff;
  background: #6584ff;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  /* background: #6d7fcc; */
  background: #ecf0f3;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  /* padding: 20px; */
  min-height: 100vh;
  transition: all 0.3s;
  background-color: #fff;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  /* #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
  } */
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  #sidebar.active {
    margin-left: 0 !important;
  }
  #sidebar .sidebar-header h3,
  #sidebar .CTAs {
    display: none;
  }
  #sidebar .sidebar-header strong {
    display: block;
  }
  /* new css */
  .main-sidebar #sidebar .sidebar-header strong {
    display: none;
  }
  .main-sidebar #sidebar .sidebar-header h3,
  #sidebar .CTAs {
    display: block;
  }
  .main-sidebar #sidebar.active {
    margin-left: 0 !important;
  }
  .main-sidebar #sidebar.active strong{
    display: block;
  }
  .main-sidebar #sidebar.active h3{
    display: none;
  }
  /* new css */
  #sidebar ul li a {
    padding: 20px 10px;
  }
  #sidebar ul li a span {
    font-size: 0.85em;
  }
  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul ul a {
    padding: 10px !important;
  }
  #sidebar ul li a i {
    font-size: 1.3em;
  }
  #sidebar {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }

  #sidebar .headerMenu {
    display: none;
  }

  #sidebar .fa-minus {
    display: none;
  }
}

.btn-info {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

#sidebar.active .headerMenu {
  display: none;
}

#sidebar.active .fa-minus {
  display: none;
}
.headerMenu {
  color: #848484;
  background: #ecf0f3;
  padding-left: 10px;
}
#sidebarCollapse {
  padding: 0.1rem 0.3rem;
}

.main-footer {
  bottom: 0;
  left: 0;
  position: inherit;
  right: 0;
  z-index: 1032;
  background-color: #ecf0f3;
  
}

::-webkit-input-placeholder {
  font-size: 16px!important;
}