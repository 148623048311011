.input-feedback {
  color: red;
  margin-top: 0.25rem;
  padding-left: 25%;
}

input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

input::-webkit-input-placeholder {
  font-size: 1rem;
}

.ck.ck-editor__editable_inline {
  height: 300px;
}

.editable-container,
.toolbar-container {
  position: relative;
  border: 1px solid #ddd;
  background: #eee;
}

.toolbar-container {
  padding: 1em;
}

.editable-container {
  padding: 3em;
  overflow-y: scroll;
  max-height: 500px;
}

.editable-container .ck-content {
  min-height: 21cm;
  padding: 2em;
  /* border: 1px #d3d3d3 solid; */
  border: 1px solid #d3d3d3;
  border-radius: var(--ck-border-radius);
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.ck.ck-editor__editable_inline[dir=ltr] {
    text-align: left;
    border-left: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    border-radius: var(--ck-border-radius);
}