#userButton[aria-expanded="true"] {
  color: #fff;
  background: transparent;
}

/* a.dropdown-toggle::after {
  color: #FFF !important;
} */

button.dropdown-toggle::after {
  color: #FFF !important;
}

.btn-link:active{
  color: #fff;
}